import React from "react";
import imgOne from "../Images/webd/WEBD.png";
import imgTwo from "../Images/webd/WEBD 2.png";
import imgThree from "../Images/webd/WEBD 3.png";
import imgFour from "../Images/webd/WEBD 4.png";
import imgFive from "../Images/webd/WEBD 5.png";
import imgSix from "../Images/webd/WEBD 6.png";

const images = [imgOne, imgTwo, imgThree, imgFour, imgFive, imgSix];

function OurServices() {
  return (
    <section>
      <div className="slider-container gap-x-2 md:flex overflow-x-scroll ">
        {images.map((src, index) => (
          <div key={index} className="block w-full md:w-1/3 px-2">
            <div className="relative h-[25em] sm:h-auto sm:w-[650px] ">
              <img
                className=" w-[500px]"
                src={src}
                alt={`Slide ${index}`}
              />
            </div>
          </div>
        ))}
      </div>
    </section>

  );
}

export default OurServices;
