import React from 'react'
import HireFromPage from '../HireFromPage/HireFromPage'

const HireFromEmergio = () => {
  return (
    <>
    <HireFromPage/>
      
    </>
  )
}

export default HireFromEmergio
