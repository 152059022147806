import { Suspense, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { Html } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

export function Model(props) {
  const { nodes, materials } = useGLTF("/AntLowPolyVersion01.gltf");
  return (
    <group {...props} dispose={null} scale={[0.5, 0.5, 0.5]}>
      <group position={[0.006, -0.399, 0.078]} scale={0.842}>
        <mesh
          geometry={nodes.Icosphere004_1.geometry}
          material={materials["Material.007"]}
        />
        <mesh
          geometry={nodes.Icosphere004_2.geometry}
          material={materials["Material.004"]}
        />
      </group>
      <group position={[-0.017, -1.702, 0.033]} scale={[0.433, 0.359, 0.433]}>
        <mesh
          geometry={nodes.Icosphere007.geometry}
          material={materials["Material.010"]}
        />
        <mesh
          geometry={nodes.Icosphere007_1.geometry}
          material={materials["Material.009"]}
        />
      </group>
      <mesh
        geometry={nodes.Icosphere009.geometry}
        material={materials["Material.011"]}
        position={[-0.036, -2.171, -0.691]}
        rotation={[-0.388, 0.027, 0.007]}
        scale={[0.721, 0.695, 1.11]}
      />
      <mesh
        geometry={nodes.Cylinder005.geometry}
        material={materials["Material.016"]}
        position={[0.383, 0.189, 0.122]}
        scale={0.079}
      />
      <mesh
        geometry={nodes.Cylinder006.geometry}
        material={materials["Material.014"]}
        position={[0.301, -1.815, 0.16]}
        rotation={[0, -0.427, -1.523]}
        scale={0.054}
      />
      <mesh
        geometry={nodes.Cylinder007.geometry}
        material={materials["Material.015"]}
        position={[0.301, -1.815, -0.064]}
        rotation={[0, 0.116, -1.523]}
        scale={0.054}
      />
      <mesh
        geometry={nodes.Cylinder008.geometry}
        material={materials["Material.013"]}
        position={[0.331, -1.123, 0.185]}
        rotation={[-0.031, 0.278, -1.459]}
        scale={[0.053, 0.043, 0.053]}
      />
      <mesh
        geometry={nodes.Cylinder009.geometry}
        material={materials["Material.012"]}
        position={[0.284, -1.159, 0.228]}
        rotation={[0, 0, -1.717]}
        scale={0.043}
      />
      <mesh
        geometry={nodes.Icosphere010.geometry}
        material={materials["Material.017"]}
        position={[-0.084, -0.585, 0.709]}
        rotation={[0.249, -0.257, 0.065]}
        scale={[0.06, 0.092, 0.03]}
      />
      <mesh
        geometry={nodes.Icosphere011.geometry}
        material={materials["Material.018"]}
        position={[-0.045, -0.79, 0.617]}
        rotation={[0.572, -0.541, -3.035]}
        scale={[0.052, 0.079, 0.026]}
      />
      <mesh
        geometry={nodes.Sphere003.geometry}
        material={materials["Material.019"]}
        position={[0.29, -0.263, 0.58]}
        rotation={[-1.392, 0, 0]}
        scale={0.208}
      />
      <mesh
        geometry={nodes.Sphere002.geometry}
        material={materials["Material.002"]}
        position={[0, -0.689, 0.185]}
        rotation={[-1.42, 0, 0]}
        scale={[0.305, 0.438, 0.058]}
      />
      <mesh
        geometry={nodes.Cylinder.geometry}
        material={nodes.Cylinder.material}
        position={[-0.019, -3.329, -0.095]}
        scale={[4.922, 0.077, 4.922]}
      />
    </group>
  );
}

function App() {
  const ref = useRef();

  return (
    <div className="App">
      <div className="product-canvas">
        <Canvas camera={{ near: 0.1, far: 1000 }}>
          <Suspense
            fallback={
              <Html>
                {" "}
                <div className="flex justify-center items-center">
                  <div className="w-20 h-20 border-2 border-opacity-20 border-white border-t-blue-500 rounded-full animate-spin" />
                </div>
              </Html>
            }
          >
            <directionalLight position={[1, 1, 1]} intensity={2} />
            <ambientLight intensity={1} />
            <pointLight position={[1, 5, 30]} intensity={1} />
            <spotLight
              position={[10, 30, 10]}
              angle={0.15}
              penumbra={1}
              intensity={2}
            />

            <Model />
            <OrbitControls
              enablePan={true}
              enableZoom={true}
              enableRotate={true}
            />
          </Suspense>
        </Canvas>
      </div>
    </div>
  );
}

export default App;
