import React from 'react'
import AboutPage from '../AboutPage/AboutPage'

const AboutUs = () => {
  return (
    <>
    <AboutPage/>
      
    </>
  )
}

export default AboutUs
