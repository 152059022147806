import React from "react";
import character from "../Images/CHARACTER__02.png";

const NewSection = () => {
  return (
    <div className="newSec ">
      <img
        src={character}
        alt="hireimage"
        className="object-cover w-[100%] md:w-[60%] h-[450px]  sm:w-[100%] "
      />
      <div>
        <h2 className="text-2xl  emergio_about mt-[100px] sm:mt-[30px]">
          #ABOUT <span className="text-orange-500">EMERGIO</span>
        </h2>
        <h6 className="emergio_desc ">
          At Emergio, we are not just a leading game development studio; we are
          also dedicated to nurturing the next generation of game creators. With
          a team of seasoned professionals and a track record of delivering
          top-tier games, we combine creativity and technical expertise to bring
          extraordinary gaming experiences to life. From concept to launch, we
          create captivating games that engage and inspire and our comprehensive
          training courses are designed to equip aspiring developers with the
          skills and knowledge needed to succeed in the dynamic world of
          game development.
        </h6>
      </div>
    </div>
  );
};

export default NewSection;
