import midnight from "../temparory/midnight.png";

const Tieupcompanies = () => {
  const scrollers = document.querySelectorAll(".scroller");
  if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
    addAnimations();
  }
  function addAnimations() {
    scrollers.forEach((scroller) => {
      scroller.setAttribute("data-animated", true);
    });
  }

  return (
    <>
      <section className="w-[100%] flex justify-center mb-12">
        <div className="w-[90%] mt-[6rem]">
          <h2 className="text-white text-[25px] sm:text-[39px] font-[700]">
            Our tie up companies
          </h2>
        </div>
      </section>

      <div className="scroller slider-container gap-8 md:flex overflow-x-scroll overflow-x- whitespace-nowrap mx-[2em] ">
        <div className="scroller_inner">
          <img className=" image_custom_class " src={midnight} alt="logo" />

          <img className=" image_custom_class " src={midnight} alt="logo" />

          <img className=" image_custom_class " src={midnight} alt="logo" />

          <img className=" image_custom_class " src={midnight} alt="logo" />

          <img className=" image_custom_class " src={midnight} alt="logo" />
          <img className=" image_custom_class " src={midnight} alt="logo" />

          <img className=" image_custom_class " src={midnight} alt="logo" />

          <img className=" image_custom_class " src={midnight} alt="logo" />

          <img className=" image_custom_class " src={midnight} alt="logo" />
        </div>
      </div>
    </>
  );
};

export default Tieupcompanies;
