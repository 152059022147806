import "./App.css";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import AboutUs from "./Components/Pages/AboutUs";
import Career from "./Components/Pages/Career";
import Courses from "./Components/Pages/Courses";
import Services from "./Components/Pages/Services";
import Home from "./Components/Pages/Home";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Placement from "./Components/Pages/Placement";
import HireFromEmergio from "./Components/Pages/HireFromEmergio";
import Entertainment from "./Components/Pages/Entertainment";
import AllVideos from "./Components/Pages/AllVideos";
import { useEffect } from "react";
import HIre from "./Components/HireFromPage/HIre";
// import AntThree from "./Components/HomePage/AntThree";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Layout = ({ children }) => {
  const location = useLocation();
  const hideHeaderFooterRoutes = ["/ant"];
  const hideHeaderFooter = hideHeaderFooterRoutes.includes(location.pathname);

  return (
    <>
      {!hideHeaderFooter && <Navbar />}
      {children}
      {!hideHeaderFooter && <Footer />}
    </>
  );
};

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/course" element={<Courses />} />
            <Route path="/service" element={<Services />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/career" element={<Career />} />
            <Route path="/placement" element={<Placement />} />
            <Route path="/hirefrom" element={<HireFromEmergio />} />
            <Route path="/entertainment" element={<Entertainment />} />
            <Route path="/viewmore" element={<AllVideos />} />
            <Route path="/hiree/:id" element={<HIre />} />
            {/* <Route path="/ant" element={<AntThree />} /> */}
          </Routes>
        </Layout>
      </Router>
    </>
  );
}

export default App;
