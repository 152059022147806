import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const HIre = () => {
  const { id } = useParams();
  const [hires, sethires] = useState([]);
  console.log(id);
  useEffect(() => {
    let data;
    axios
      .get("https://www.emergiogames.com/emergio/hire", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        data = res.data;
        sethires(data);
      })
      .catch((err) => {});
  }, []);

  return (
    <main className="bg-black md:pt-0  sm:p-0">
      <section>
        <div className="">
          <h2 className="text-white  pt-[220px] ml-20  text-5xl font-black  tracking-wide">
            Unity Game Developers
          </h2>
        </div>
        <div className="hire__container">
          {hires.map((detail, id) => (
            <div className="hire__div">
              <img
                src={`https://www.emergiogames.com${detail.image}`}
                className="sm:w-[50%] w-[100%]"
                alt="image"
              />
              <button className="hire__btn">
                <a href="https://api.whatsapp.com/send?phone=917594088814">
                  HIRE NOW
                </a>
              </button>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default HIre;
