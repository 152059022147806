import card2 from "../Images/card2.png";
import card3 from "../Images/card3.png";

// new
import crscard from "../Images/crscard.png";
import crscard1 from "../Images/crscard1.png";
import crscard2 from "../Images/crscard2.png";
import crscard3 from "../Images/crscard3.png";
import crscard4 from "../Images/crscard4.png";
import crscard5 from "../Images/crscard5.png";
import crscard6 from "../Images/crscard6.png";
import crscard7 from "../Images/crscard7.png";
import crsicon1 from "../Images/crsicon1.png";
import crsicon2 from "../Images/crsicon2.png";
import crsicon3 from "../Images/crsicon3.png";
import crsicon4 from "../Images/crsicon4.png";
import crsicon5 from "../Images/crsicon5.png";
import crsicon6 from "../Images/crsicon6.png";
import crsicon7 from "../Images/crsicon7.png";
import crsicon8 from "../Images/crsicon8.png";

// entertainment cards

import sliderimg from '../Images/sliderimg.png'
// shorts
import shortsimg from "../Images/shortsimg.png";
// our team
import  arun from '../Images/arun.png'
import benin from '../Images/benin.png'
import amrutha from '../Images/amrutha.png'
import sharaf from '../Images/sharaf.png'
import abin from '../Images/abin.png'
import hari from '../Images/hari.png'
import nisha from '../Images/nisha.png'
import asu from '../Images/asu.png'
import goutham from '../Images/goutham.png'
import ashik from '../Images/ashik.png'
import basi from '../Images/basi.png'
import akhil from '../Images/akhil.png'
import shahbayan from '../Images/shahbayan.png'
import vishnu from '../Images/vishnu.png'
import nayana from '../Images/nayana.png'
// career
import careericon1 from '../Images/careericon1.png'
import careericon2 from '../Images/careericon2.png'
import careericon3 from '../Images/careericon3.png'
// placements
import prince from '../Images/prince.png'
import erfana from '../Images/erfana.png'
import kulsu from '../Images/kulsu.png'
import muhammed from '../Images/muhammed.png'
import basil from '../Images/basil.png'
import vishnump from '../Images/vishnump.png'
import della from '../Images/della.png'
import akhila from '../Images/akhila.png'
import abinas from '../Images/abinas.png'
import shon from '../Images/shon.png'
const coursecard = [
  {
    id: 1,
    img: crscard1,
    icon: crsicon1,
    title: "Game Developement",
    text: "Game development refers to the process of creating video games, including planning, designing, coding, testing, and launching them. It's a multidisciplinary field that involves various professionals.",
  },
  {
    id: 2,
    img: crscard2,
    icon: crsicon2,
    title: "Game Art",
    text: "The visual elements of a video game, including characters, environments, objects, animations, and user interface (UI) design. It encompasses various artistic disciplines.",
  },
  {
    id: 3,
    img: crscard,
    icon: crsicon3,
    title: "AR VR",
    text: "Augmented Reality (AR) and Virtual Reality (VR) are immersive technologies that alter the way users perceive and interact with the digital and physical world. While they share similarities.",
  },
];

const entertainmentCards = [
  {
    id: 1,
    img: sliderimg,
  },
  {
    id: 2,
    img: sliderimg,
  },
  {
    id: 3,
    img: sliderimg,
  },
  {
    id: 4,
    img: sliderimg,
  },
  {
    id: 5,
    img: sliderimg,
  },
  {
    id: 6,
    img: sliderimg,
  },
];
const shorts = [
  {
    id: 1,
    img: shortsimg,

  },
  {
    id: 2,
    img: shortsimg,
    margin: "mt-[-2rem]",
  },
  {
    id: 3,
    img: shortsimg,

  },
  {
    id: 4,
    img: shortsimg,
    margin: "mt-[-2rem]",
  },
  {
    id: 5,
    img: shortsimg,

  },
];
const coursecardpage = [
  {
    id: 1,
    img: crscard3,
    icon: crsicon4,
    title: "Human Resource Management",
    text: "Nurturing organizational talent. Explore our courses for insights into recruitment, employee management, performance optimization, and compliance strategies.",
  },
  {
    id: 2,
    img: crscard4,
    icon: crsicon5,
    title: "UIUX",
    text: "Designing user-friendly digital journeys. Join our courses to master interface design and user experience strategies, creating engaging digital landscapes.",
  },
  {
    id: 3,
    img: crscard7,
    icon: crsicon6,
    title: "Digital Marketing",
    text: "Strategic, data-driven promotion. Our courses cover social media, SEO, content creation, and analytics, empowering you to navigate today's digital landscape and drive results.",
  },
  {
    id: 4,
    img: crscard5,
    icon: crsicon1,
    title: "Python",
    text: "The language of possibilities. Join our courses to explore its simplicity and versatility in web development, AI, data analysis, and automation.",
  },
  {
    id: 5,
    img: crscard2,
    icon: crsicon7,
    title: "Game Art",
    text: "Where imagination takes form. Enroll in our courses to master visual storytelling, character design, and world creation, shaping the essence of gaming experiences.",
  },
  {
    id: 6,
    img: crscard6,
    icon: crsicon8,
    title: "Game Development",
    text: "Craft gaming worlds from imagination to reality. Our courses guide you through every step of game development, unlocking the skills to bring your ideas to life.",
  },
];
const OurTeam = [
  {
    id: 1,
    img: nisha,
    name: "Nisha Chandran",
    job: "CEO",
  },
  {
    id: 2,
    img: basi,
    name: "Abdul Basith",
    job: "Chief of Operations",
  },
  {
    id: 3,
    img: arun,
    name: "Arun Sudhakaran",
    job: "Chief Technology Officer",
  },
  {
    id: 4,
    img: asu,
    name: "Asmath Basheer",
    job: "Chief of Human Resource",
  },
  {
    id: 5,
    img:benin,
    name: "Benin P John",
    job: "Chief Game Director",
  },
  {
    id: 6,
    img: hari,
    name: "Harikrishnan V S",
    job: "Associate Unity Developer",
  },
  {
    id: 7,
    img: amrutha,
    name: "Senior BDM",
    job: "Founder&CEO",
  },
  {
    id: 8,
    img: ashik,
    name: "Ashik Muhammed",
    job: "Senior BDM",
  },
  {
    id: 9,
    img: sharaf,
    name: "Sharafudheen",
    job: "Associate Placement Officer",
  },
  {
    id: 10,
    img:vishnu ,
    name: "Vishnu M P",
    job: "Associate Game Artist",
  },
  {
    id: 11,
    img: shahbayan,
    name: "Shahbayan Sidhique",
    job: "Associate Unity Developer",
  },
  {
    id: 12,
    img: abin,
    name: "Abin",
    job: "Associate Unity Developer",
  },
  {
    id: 13,
    img: goutham,
    name: "Goutham",
    job: "Associate Unity Developer",
  },
  {
    id: 12,
    img: akhil,
    name: "Akhil N",
    job: "Associate Unity Developer",
  },
  {
    id: 12,
    img: nayana,
    name: "Nayana Mohan",
    job: "Lead Python Developer",
  },
];
const careercard=[
  {
    id:1,
    img:careericon1,
    title:'Career Growth Opportunities',
    text:'With the rapid advancement of technology, there is a high demand for skilled professionals in game development, digital marketing, UIUX designing etc. Staying adaptable and  developing new skills will help you thrive in any career path you choose.'
  },
  {
    id:2,
    img:careericon2,
    title:'Work Environment',
    text:'By implementing proper strategies, can create a positive work environment where employees feel motivated, engaged, and supported, leading to greater satisfaction, productivity, and success.'
  },
  {
    id:3,
    img:careericon3,
    title:'Recruitment Process',
    text:'The recruitment process begins with identifying the hiring needs within the organization. This involves determining the number of positions to fill, the required skills and qualifications, and the budget for recruitment.'
  }
]
 const openpositions=[
  {
    id:1,
    role:'Flutter Developer',
    exp:'2+year'
  },
  {
    id:1,
    role:'Flutter Developer',
    exp:'2+year'
  },
  {
    id:1,
    role:'Flutter Developer',
    exp:'2+year'
  },
  {
    id:1,
    role:'Flutter Developer',
    exp:'2+year'
  }
 ]
 const placements=[

  {
    id:3,
    img:kulsu,
   
  },
  {
    id:4,
    img:muhammed,
  
  },
  {
    id:5,
    img:basil,
  
  },
  {
    id:6,
    img:vishnump,
  
  },
  {
    id:7,
    img:della,
  
  },
  {
    id:8,
    img:akhila,
  
  },
  {
    id:9,
    img:abinas,
  
  },
  {
    id:10,
    img:shon,
  
  },
 ]
 const casinogames=[
  {
    id: 1,
    img: sliderimg,
  },
  {
    id: 2,
    img: sliderimg,
  },
  {
    id: 3,
    img: sliderimg,
  },
 ]
 
export { coursecard, entertainmentCards, shorts, coursecardpage, OurTeam,careercard,openpositions ,placements,casinogames};
