import React from 'react'
import CoursePage from '../CoursePage/CoursePage'

const Courses = () => {
  return (
    <>
    <CoursePage/>
      
    </>
  )
}

export default Courses
