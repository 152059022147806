import React from 'react'
import CareerPage from '../CareerPage/CareerPage'

const Career = () => {
  return (
    <>
    <CareerPage/>
      
    </>
  )
}

export default Career
