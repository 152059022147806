import React from "react";
// import { useRef } from "react";
import circle from "../Images/circle.png";
import kid from "../Images/kid.png";
import wannabg from "../Images/wannabg.png";
import wannaimg from "../Images/wannaimg.png";
import casino from "../Images/casino.png";
import casinobg from "../Images/casinobg.png";
import casinobg2 from "../Images/casinobg2.png";
import { entertainmentCards } from "../Datas/Datas";
import { Link } from "react-router-dom";
import { shorts } from "../Datas/Datas";
import retro from "../Images/retro.png";
import strategy from "../Images/strategy.png";
import casual from "../Images/casual.png";
import "swiper/swiper-bundle.css";
import "./HomePage.css";
import ModalForm from "./ModalForm";
import axios from "axios";
import { FaPlayCircle } from "react-icons/fa";
import Tieupcompanies from "./Tieupcompanies";
import OurServices from "./OurServices"
import Three from "./Three";
import ThreeTwo from "./ThreeTwo";
import AntThree from "./AntThree";
import Games from "./Games";
class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.groupRef = React.createRef();
  }
  state = {
    details: [],
    new: [],
    stories: [],
    videos: shorts,
    entertainmentCard: entertainmentCards,
    showModal: false,
    games: [],
  };
  handleModalOpen = () => {
    this.setState({ showModal: true });
  };
  handleModalClose = () => {
    this.setState({ showModal: false });
  };
  componentDidMount() {
    let data, news, story, game;
    axios
      .get("https://www.emergiogames.com/emergio/course", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        data = res.data.slice(0, 3);
        this.setState({
          details: data,
        });
        //console.log(data);
      })
      .catch((err) => {});
    axios
      .get("https://www.emergiogames.com/emergio/news", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        news = res.data;
        this.setState({
          new: news,
        });
      })
      .catch((err) => {});
    axios
      .get("https://www.emergiogames.com/emergio/story", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        data = res.data.slice(0, 4);
        this.setState({
          stories: data,
        });
      })
      .catch((err) => {});
    axios
      .get("https://www.emergiogames.com/emergio/game", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        game = res.data;
        this.setState({
          games: game,
        });
      })
      .catch((err) => {});
    const slider = document.querySelector(".slider-container");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener("mouseleave", () => {
      isDown = false;
    });

    slider.addEventListener("mouseup", () => {
      isDown = false;
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1;
      slider.scrollLeft = scrollLeft - walk;
    });
  }
  render() {
    const { groupRef, showModal } = this.state;
    return (
      <>
        {/* news section */}
        <main className="main w-[100%]">
          <section className="w-[100%] text-white  flex justify-center items-center">
            <div className="w-[90%] mt-[4rem] mb-[4em] flex flex-wrap justify-around gap-3">
              <div className="flex flex-wrap flex-col sm:mt-[2em] mt-[-1em]">
                {this.state.new.map((data, id) => (
                  <div className="news-card w-[20em] h-[270px] px-5 pt-4  flex-col mt-[2em]">
                    <h5 className="text-[20px]">{data.header}</h5>
                    <h5 className=" text-[13px] pt-3">{data.description}</h5>
                  </div>
                ))}
              </div>
              <div className="relative  mt-12">
                <img
                  className="mt-[-1rem] sm:h-[400px]  md:h-[500px] md:w-[500px] lg:h-[594px] lg:w-[608px]"
                  src={circle}
                  alt=""
                />
                {/* <Three /> */}
                {/* <ThreeTwo /> */}
                <img
                  className="absolute top-[0%] left-[20%] h-[250px] sm:h-[320px] md:top-[0%] md:left-[20%]  md:h-[395px] lg:h-[483px] lg:w-[377px]"
                  src={kid}
                  alt=""
                />
              </div>
            </div>
            {/* 3D character */}

            {/* end */}
          </section>
          {/* trending course */}
          <section className="w-[100%] flex flex-col justify-center items-center mt-[4rem]">
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="w-[90%]"
            >
              <h4 className="text-white text-[39px] font-[700]">
                Discover the Latest Trends
              </h4>
              <h5 className="text-[25px] text-[#21C8ED]">Our Courses</h5>
            </div>

            {/* course cards */}
            <div className="custom_outer">
              {this.state.details.map((detail, id) => (
                <div className="custom_class">
                  <img
                    className="crs-img w-[100%]  sm:w-[329px] h-[250px] relative transition-transform duration-300 transform hover:scale-105"
                    src={`https://www.emergiogames.com${detail.image}`}
                    alt=""
                  />
                  <img
                    className="sm:w-[80px] w-[60px] h-[60px] block mt-[-2.2em] mx-auto sm:h-[80px] z-10 border-2 border-[#21C8ED] rounded-full"
                    src={`https://www.emergiogames.com${detail.icon}`}
                    alt=""
                  />
                  <h4 className="text-white text-[20px] font-[700] pt-[3rem]  ">
                    {detail.name}
                  </h4>
                  <p className=" mt-3 mb-5 h-full text-ellipsis overflow-hidden">
                    {detail.description}
                  </p>
                  <a
                    href={`https://www.emergiogames.com${detail.syllabus}`}
                    className="custom_a"
                  >
                    SYLLABUS
                  </a>
                </div>
              ))}
              {/* {showModal && <ModalForm onClose={this.handleModalClose} />} */}
            </div>
          </section>
          {/* wanna play or wanna develop section */}
          <section>
            <div className="flex justify-center mt-[3rem] sm:mt-[10rem] relative">
              <img
                className=" object-cover h-[500px] md:h-[500px] lg:h-[400px]"
                src={wannabg}
                alt=""
              />
              <div className="absolute text-white  flex  flex-wrap  left-[15%] ">
                <div className="flex   items-center  sm:justify-start flex-wrap lg:flex-nowrap  mt-[3rem]  md:gap-10 ">
                  <div className="w-[90%] sm:w-[376px] ">
                    <h2 className="sm:text-[25px] text-[18px] font-[700]">
                      WANNA PLAY A GAME
                    </h2>
                    <p className="sm:text-[16px] text-[12px] mt-[0.5em]">
                      Ready for gaming thrills? Dive in now! Discover
                      adventures, puzzles, and challenges awaiting your play.
                    </p>
                    <button
                      onClick={this.handleModalOpen}
                      className="vdo-icon bg-transparent w-[140px] h-[50px]  hover:bg-[#21C8ED] text-[13px] font-[700] border-[0.568px] border-white mt-[2rem] hover:bg- transition-colors duration-500 text-white  py-2 px-4"
                    >
                      CONTACT US
                    </button>
                  </div>
                  <div className="md:hidden lg:block md:h-[150px] lg:h-[200px] w-[5px] rounded-md bg-white mt-3"></div>
                  <div className="w-[90%] sm:w-[376px] flex flex-col md:mt-0  mt-[3rem] ">
                    <h2 className="sm:text-[25px] text-[18px] font-[700]">
                      WANNA DEVELOP A GAME
                    </h2>
                    <p className="sm:text-[16px] text-[12px] mt-[0.5em]">
                      Turn your game ideas into reality! Join our game
                      development courses and create captivating worlds.
                    </p>
                    <button
                      onClick={this.handleModalOpen}
                      className="vdo-icon bg-transparent w-[140px] h-[50px] hover:bg-[#21C8ED]  text-[13px] font-[700] border-[0.568px] border-white mt-[2rem] hover:bg- transition-colors duration-500 text-white  py-2 px-4"
                    >
                      CONTACT US
                    </button>
                  </div>
                   <img
                    className="char-girl lg:mt-[-11rem] md:mt-[-9rem] sm:mt-[-12rem] sm:w-[180px] md:w-[250px] h-[250px] lg:h-auto lg:w-auto hidden sm:block "
                    src={wannaimg}
                    alt=""
                  />
                </div>
              </div>
              {showModal && <ModalForm onClose={this.handleModalClose} />}
            </div>
          </section>
          {/* emergios services */}
          <OurServices/>

          {/* company logos */}
          <Tieupcompanies />

          {/* end */}

          {/* entertainments */}
          <section className="w-[100%] flex justify-center">
            <div className="w-[90%] mt-[6rem]">
              <h2 className="text-white text-[25px] sm:text-[39px] font-[700]">
                Welcome to World of Entertainments
              </h2>
              <p className=" text-[#21C8ED] text-[25px]">Let’s Play</p>
            </div>
          </section>
          {/* entertainment cards slider */}
          <section>
            {/* slider */}
            <div className="slider-container gap-8 md:flex overflow-x-scroll overflow-x- whitespace-nowrap mx-[2em]">
              {this.state.games.map((data, index) => (
                <div key={index} className="inline-block ">
                  <div className="ent-card-slider relative h-[20em] sm:h-[420px] sm:w-[420px] w-[100%] mt-[2em] sm:mt-[2rem]">
                    <img
                      className=" w-[100%] h-[415px] rounded-lg"
                      src={`https://www.emergiogames.com${data.image}`}
                      alt=""
                    />
                    <button className="w-[177px] h-[66px] text-white absolute sm:top-[50%] top-[40%] left-[25%] sm:left-[30%]">
                      <Link to="/entertainment">Play Now</Link>
                    </button>
                  </div>
                </div>
              ))}
            </div>
            {/* catagory */}
          </section>
          <Games />

          {/*  shorts */}
          <section>
            <div className="mt-[5rem] px-10">
              <h1 className="text-white text-[39px] font-[700]">
                Captivating Experience
              </h1>
              <h5 className="text-[25px]  text-[#21C8ED]">Our Stories</h5>
            </div>
            <div className="flex justify-center flex-col items-center mt-[1.5rem]">
              <div className="flex flex-wrap justify-center  gap-12">
                {this.state.stories.map((data, id) => (
                  <div
                    className="card-story"
                    style={{
                      position: "relative",
                      border: "2px solid #21C8ED",
                    }}
                    key={id}
                  >
                    <img
                      className=" w-[21em] h-[30em] rounded-md"
                      src={`https://www.emergiogames.com${data.thumbnail}`}
                      alt=""
                    />
                    <a
                      className=" absolute bottom-[50%] left-[45%]  text-[40px] z-10 text-white mb-[-0.5em]"
                      href={data.link}
                    >
                      <FaPlayCircle />
                    </a>
                  </div>
                ))}
              </div>
              <Link
                to="/viewmore"
                className="vdo-icon bg-transparent w-[140px] h-[50px] text-center items-center justify-center hover:bg-[#21C8ED] mb-[3em] text-[13px] font-[700] border-[0.568px] border-white mt-[3rem] hover:bg- transition-colors duration-500 text-white  py-2 px-4"
              >
                VIEW MORE
              </Link>
            </div>
          </section>
        </main>
      </>
    );
  }
}
export default HomePage;
