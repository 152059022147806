import React, { Component } from "react";
import axios from "axios";
import { FaPlayCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

class AllVideos extends Component {
  constructor(props) {
    super(props);
    this.groupRef = React.createRef();
    this.state = {
      stories: [],
      // Ensure 'shorts' is defined or passed as a prop
      // Ensure 'entertainmentCards' is defined or passed as a prop
      showModal: false,
    };
  }

  handleModalOpen = () => {
    this.setState({ showModal: true });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  componentDidMount() {
    let data, news, story, game;

    axios
      .get("https://www.emergiogames.com/emergio/story", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        story = res.data;
        this.setState({
          stories: story,
        });
      })
      .catch((err) => {});
  }

  render() {
    console.log(this.state.stories);
    return (
      <main className="main w-[100%]">
        <section>
          <div className="mt-[5rem] p-[100px] ">
            <h1 className="text-white text-[39px] font-[700]">
              Captivating Experience
            </h1>
            <h5 className="text-[25px]  text-[#21C8ED]">Our Stories</h5>
          </div>
          <div className="flex justify-center flex-col items-center mt-[1.5rem] px-8">
            <div className="flex flex-wrap justify-center  gap-12 mb-20">
              {this.state.stories.map((data, id) => (
                <div
                  className="card-story"
                  style={{
                    position: "relative",
                    border: "2px solid #21C8ED",
                  }}
                  key={id}
                >
                  <img
                    className=" w-[19em] h-[25em] rounded-md"
                    src={`https://www.emergiogames.com${data.thumbnail}`}
                    alt=""
                  />
                  <a
                    className=" absolute bottom-[50%] left-[45%]  text-[40px] z-10 text-white mb-[-0.5em]"
                    href={data.link}
                  >
                    <FaPlayCircle />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default AllVideos;
