import React, { useEffect, useState } from "react";
import "./CareerPage.css";
import axios from "axios";
import FormData from 'form-data';

class Form extends React.Component {
  state = {
    name: '',
    email: '',
    phone: '',
    position: '',
    resume: null,
    isSubmitted: false,
    formData: new FormData(),
    status: '',
    message: '',
  }
  constructor(props) {
    super(props);
    this.roleInputRef = React.createRef();
  }
  handleChange = event => {
    this.setState({ name: event.target.value });
  }
  changePhone = event => {
    this.setState({ phone: event.target.value });
  }
  changeEmail = event => {
    this.setState({ email: event.target.value });
  }
  changeRole = event => {
    this.setState({ position: event.target.value });
  }
  changeResume = event => {
    this.setState({ resume: event.target.files[0] });
    console.log(this.state.resume, event.target.files[0])
  }
  handleSubmit = event => {
    event.preventDefault();
    const roleValue = this.roleInputRef.current.value;
    console.log(roleValue, this.state.resume)
    const formData = new FormData();
    formData.append('resume', this.state.resume);
    formData.append('name', this.state.name);
    formData.append('phone', this.state.phone);
    formData.append('position', roleValue);
    formData.append('email', this.state.email);
    this.setState({
      name: '', email: '',
      phone: '',
    });
    axios.post(`https://www.emergiogames.com/emergio/application`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        this.setState({ status: res.data.status, message: res.data.message })
      })
    this.setState({ isSubmitted: true });
    setTimeout(() => {
      this.props.onClose();
    }, 2000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isSubmitted !== prevState.isSubmitted && this.state.isSubmitted) {
      const timer = setTimeout(() => {
        this.setState({ isSubmitted: false });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }
  render() {
    const { isSubmitted, status, message } = this.state;
    const { role } = this.props;
    return (
      <>
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex">
          <div className="relative p-8 bg-white w-[90%] md:w-[30%] mt-[9rem] m-auto rounded-lg">
            <button
              className="absolute top-0 right-0 mt-4 mr-4 text-gray-500 hover:text-gray-800"
              onClick={this.props.onClose}
            >
              <svg
                className="w-6 h-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 11.293a1 1 0 0 1 1.414 1.414L11.414 14l2.707 2.707a1 1 0 1 1-1.414 1.414L10 15.414l-2.707 2.707a1 1 0 0 1-1.414-1.414L8.586 14 5.879 11.293a1 1 0 0 1 1.414-1.414L10 12.586l2.707-2.707a1 1 0 0 1 1.414 1.414L11.414 14l2.293 2.293a.997.997 0 0 1 0 1.414.999.999 0 0 1-1.414 0L10 15.414l-2.293 2.293a.999.999 0 0 1-1.414 0 .997.997 0 0 1 0-1.414L8.586 14 6.879 11.293A.999.999 0 0 1 8.293 9.88L10 11.586l1.707-1.707a.999.999 0 1 1 1.414 1.414L11.414 14l2.293 2.293a.999.999 0 0 1 0 1.414.997.997 0 0 1-1.414 0L10 15.414l-1.707 1.707a.999.999 0 0 1-1.414 0 .997.997 0 0 1 0-1.414L8.586 14l-1.707-1.707a.999.999 0 0 1 0-1.414 1 1 0 0 1 1.414 0L10 12.586l1.707-1.707a1 1 0 0 1 1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <h2 className=" px-8  mb-4 text-[#21C8ED] text-[25px] md:text-[34px] font-[700]">
              Welcome to Emergio Games!
            </h2>

            <form onSubmit={this.handleSubmit}>
              <div className="flex justify-center flex-col gap-5 mt-3 flex-wrap ">
                <div className="">
                  <input
                    placeholder="Name"
                    type="text"
                    id="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    className="w-[100%] md:w-[25vw] px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
                <div className="">
                  <input
                    placeholder="Phone No"
                    type="tel"
                    id="phone"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.changePhone}
                    className="w-[100%] md:w-[25vw] px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
                <div className="">
                  <input
                    placeholder="Email"
                    type="email"
                    value={this.state.email}
                    id="email"
                    onChange={this.changeEmail}
                    name="email"
                    className="w-[100%] md:w-[25vw] px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
                <div>
                  <input
                    readOnly
                    placeholder="role"
                    value={role}
                    type="text"
                    id="role"
                    ref={this.roleInputRef}
                    name="role"
                    className="w-[100%] md:w-[25vw] px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  />
                </div>

              </div>
              <div className=" flex md:justify-start justify-center">
                <div className="w-[150px] h-[50px] rounded-[9px] cursor-pointer flex justify-center items-center gap-2 mt-[2rem] bg-[#F5BC28] text-white">
                  <div class="button-wrapper w-[196px] h-[50px] bg-[#F5BC28] flex justify-center items-center rounded-[9px]">
                    <span class="label flex">Upload File</span>
                    <input
                      type="file"
                      name="resume"
                      onChange={this.changeResume}
                      id="upload"
                      class="upload-box"
                      placeholder="Upload File"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center   flex-wrap "></div>
              <div className="flex justify-center md:justify-start sm:w-[90%]">
                <button
                  type="submit"
                  className="w-[150px] h-[36px] bg-[#21C8ED]  text-white  text-[16px] rounded-[9px] flex justify-center items-center text-center font-[700] mt-[1rem]   hover:bg-blue-600 transition-colors float-end"
                >
                  Apply
                </button>
              </div>
            </form>
          </div >
        </div >

        {/* Alert modal */}
        {
          isSubmitted && (
            <div className="fixed inset-0 z-50 flex items-center justify-center ">
              <div className="bg-white p-8 rounded-lg shadow-lg">
                {status ? ( // Using ternary operator to conditionally render content
                  <div className="text-5xl text-green-500">&#10004;</div>

                ) : (
                  <div className="text-5xl text-red-500">&#10006;</div>
                )}
                <p className="text-center text-xl">
                  {message.toString()}
                </p>
              </div>
            </div>
          )
        }
      </>
    );
  };
}
export default Form;
