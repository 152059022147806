import React from 'react'
import PlacementPage from '../PlacementPage/PlacementPage'

const Placement = () => {
  return (
    <>
    <PlacementPage/>
      
    </>
  )
}

export default Placement
