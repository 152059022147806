import React, { useState, useEffect } from "react";
import logo from "../Images/logo.png";
import { RiMenu3Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import { useLocation } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const { top } = document.body.getBoundingClientRect();
      setVisible(top >= -600); // Adjust threshold as needed
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const location = useLocation();

  if (location.pathname === "/login") {
    return null;
  }

  return (
    <>
      <div className={` ${visible ? "visible" : "hidden"}`}>
        <header className="custom_nav navbar z-50 fixed flex justify-center h-[80px] md:h-[117px] w-[100%]   bg-black ">
          <nav className=" flex items-center w-[90%] md:w-[100%] text-white text-[16px] font-[600] justify-between md:justify-around">
            <Link to="/">
              {" "}
              <img className="w-[150px] h-[35px] " src={logo} alt="" />{" "}
            </Link>
            <a className="md:block hidden cursor-pointer  hover:text-[#21C8ED]">
              <Link to="/">Home </Link>
            </a>
            <a className="md:block hidden cursor-pointer  hover:text-[#21C8ED]">
              <Link to="/course">Learn </Link>
            </a>
            <a className="md:block hidden cursor-pointer  hover:text-[#21C8ED]">
              <Link to="/service">Services </Link>
            </a>

            <a className="md:block hidden cursor-pointer hover:text-[#21C8ED]">
              {" "}
              <Link to="/placement">Placements </Link>
            </a>

            <RiMenu3Fill onClick={openModal} className=" md:hidden" />
            <a className="md:block hidden cursor-pointer hover:text-[#21C8ED]">
              <Link to="/hirefrom">Hire from Emergio </Link>
            </a>
            <a className="md:block hidden cursor-pointer hover:text-[#21C8ED]">
              <Link to="/entertainment">Entertainment</Link>
            </a>
            <a className="md:block hidden cursor-pointer hover:text-[#21C8ED]">
              <Link to="/career">Career</Link>
            </a>
            <a className="md:block hidden cursor-pointer hover:text-[#21C8ED]">
              <Link to="/about">About Us</Link>
            </a>
          </nav>

          {/* sidebar */}

          {isModalOpen && (
            <Modal isOpen={isModalOpen} onClose={closeModal}>
              <div className=" flex flex-col fixed bg-[#0F0D0D7A] h-[100vh] w-[80%] top-[-0%] text-[20px] justify-center items-start px-[2rem] ">
                <a className="text-white mt-[3rem] cursor-pointer  hover:text-[#21C8ED]">
                  <Link onClick={closeModal} to="/">
                    Home{" "}
                  </Link>
                </a>
                <a className="text-white mt-[3rem] cursor-pointer  hover:text-[#21C8ED]">
                  <Link onClick={closeModal} to="/course">
                    Learn{" "}
                  </Link>
                </a>
                <a className="text-white mt-[3rem] cursor-pointer  hover:text-[#21C8ED]">
                  <Link onClick={closeModal} to="/service">
                    Learn{" "}
                  </Link>
                </a>
                <a className="text-white mt-[3rem]  hover:text-orange-600">
                  <Link onClick={closeModal} to="/placement">
                    Placements
                  </Link>
                </a>
                <a className=" cursor-pointer mt-[3rem] text-white hover:text-[#21C8ED]">
                  <Link onClick={closeModal} to="/hirefrom">
                    Hire from Emergio{" "}
                  </Link>
                </a>
                <a className="text-white mt-[3rem]  cursor-pointer hover:text-[#21C8ED]">
                  <Link onClick={closeModal} to="/entertainment">
                    Entertainment
                  </Link>
                </a>
                <a className="text-white mt-[3rem]  cursor-pointer hover:text-[#21C8ED]">
                  <Link onClick={closeModal} to="/about">
                    About Us
                  </Link>
                </a>
                <a className="text-white mt-[3rem] cursor-pointer hover:text-[#21C8ED]">
                  <Link onClick={closeModal} to="/career">
                    Career
                  </Link>
                </a>
              </div>
            </Modal>
          )}
        </header>
      </div>
    </>
  );
};

export default Navbar;
