import React from 'react'
import EntertainmentPage from '../EntertainmentPage/EntertainmentPage'

const Entertainment = () => {
  return (
    <>
      <EntertainmentPage/>
    </>
  )
}
export default Entertainment
