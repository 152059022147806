import React from "react";
import axios from "axios";
class ModalForm extends React.Component {
  state = {
    name: '',
    email: '',
    phone: '',
    place: '',
    message: '',
    isSubmitted: false,
    formData: new FormData(),
    status: '',
    stat_message: '',
  }
  changeName = event => {
    this.setState({ name: event.target.value });
  }
  changePhone = event => {
    this.setState({ phone: event.target.value });
  }
  changeEmail = event => {
    this.setState({ email: event.target.value });
  }
  changePlace = event => {
    this.setState({ place: event.target.value });
  }
  changeMessage = event => {
    this.setState({ message: event.target.value });
  }
  handleSubmit = event => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', this.state.name);
    formData.append('phone', this.state.phone);
    formData.append('email', this.state.email);
    formData.append('place', this.state.place);
    formData.append('message', this.state.message);
    this.setState({
      name: '', email: '',
      phone: '', place: '', message: '',
    });
    axios.post(`https://www.emergiogames.com/emergio/contact`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        this.setState({ status: res.data.status, stat_message: res.data.message })
      })
    this.setState({ isSubmitted: true });
    setTimeout(() => {
      this.props.close();
    }, 2000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isSubmitted !== prevState.isSubmitted && this.state.isSubmitted) {
      const timer = setTimeout(() => {
        this.setState({ isSubmitted: false });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }
  render() {
    const { isSubmitted, status, stat_message } = this.state;
    return (
      <>
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex sm:pt-[6rem]">
          <div className="relative p-8 bg-white w-[90%] md:w-[60%]  m-auto rounded-lg">
            <button
              className="absolute top-0 right-0 mt-4 mr-4 text-gray-500 hover:text-gray-800"
              onClick={this.props.onClose}
            >
              <svg
                className="w-6 h-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 11.293a1 1 0 0 1 1.414 1.414L11.414 14l2.707 2.707a1 1 0 1 1-1.414 1.414L10 15.414l-2.707 2.707a1 1 0 0 1-1.414-1.414L8.586 14 5.879 11.293a1 1 0 0 1 1.414-1.414L10 12.586l2.707-2.707a1 1 0 0 1 1.414 1.414L11.414 14l2.293 2.293a.997.997 0 0 1 0 1.414.999.999 0 0 1-1.414 0L10 15.414l-2.293 2.293a.999.999 0 0 1-1.414 0 .997.997 0 0 1 0-1.414L8.586 14 6.879 11.293A.999.999 0 0 1 8.293 9.88L10 11.586l1.707-1.707a.999.999 0 1 1 1.414 1.414L11.414 14l2.293 2.293a.999.999 0 0 1 0 1.414.997.997 0 0 1-1.414 0L10 15.414l-1.707 1.707a.999.999 0 0 1-1.414 0 .997.997 0 0 1 0-1.414L8.586 14l-1.707-1.707a.999.999 0 0 1 0-1.414 1 1 0 0 1 1.414 0L10 12.586l1.707-1.707a1 1 0 0 1 1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <h2 className=" sm:px-8 mt-[0.5em] mb-4 text-[#21C8ED] text-[25px] md:text-[34px] font-[700]">
              Welcome to Emergio Games!
            </h2>
            <p className="sm:px-9 text-[14px] mt-[-1em] mb-[3em] font-[400]">
              Please fill this form and explore our gaming world ...
            </p>
            <form onSubmit={this.handleSubmit}>
              <div className="flex justify-center gap-5 flex-wrap ">
                <div className="">
                  <input
                    placeholder="Name"
                    type="text"
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={this.changeName}
                    className="w-[100%] md:w-[350px] px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
                <div className="">

                  <input placeholder="Phone No"

                    type="tel"
                    id="phone"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.changePhone}
                    className="w-[100%] md:w-[350px] px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
              </div>
              <div className="flex justify-center gap-5 mt-[1.5rem] flex-wrap ">
                <div className="">
                  <input
                    placeholder="Email"
                    type="email"
                    value={this.state.email}
                    id="email"
                    onChange={this.changeEmail}
                    name="email"
                    className="w-[100%] md:w-[350px] px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
                <div className="">
                  <input placeholder="Place"
                    type="text"
                    id="place"
                    name="place"
                    value={this.state.place}
                    onChange={this.changePlace}
                    className="w-[100%] md:w-[350px] px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
              </div>
              <div className="flex justify-center  gap-5 mt-[1.5rem] flex-wrap ">
                <div className="w-[100%]  flex justify-center">
                  <textarea
                    placeholder="Message"
                    id="message"
                    name="message"
                    value={this.state.message}
                    onChange={this.changeMessage}
                    className="w-[70%] md:w-[75%] px-5 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    rows="4"
                    required
                  />
                </div>
              </div>
              <div className="flex justify-center mb-[0.5em]">
                <button
                  type="submit"
                  className="w-[195px] h-[56px] bg-[#21C8ED]  text-white px-4 text-[21px] font-[700] mt-[3rem] py-2  hover:bg-blue-600 transition-colors float-end"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        {
          isSubmitted && (
            <div className="fixed inset-0 z-50 flex items-center justify-center ">
              <div className="bg-white p-8 rounded-lg shadow-lg">
                {status ? (
                  <div className="text-5xl text-green-500">&#10004;</div>

                ) : (
                  <div className="text-5xl text-red-500">&#10006;</div>
                )}
                <p className="text-center text-xl">
                  {stat_message.toString()}
                </p>
              </div>
            </div>
          )
        }
      </>
    );
  };
}
export default ModalForm;