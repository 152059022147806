// import React, { useState } from "react";
// import circle from "../Images/circle.png";
// import "./CoursePage.css";
// import img_1 from "../Images/servicePageImg/assets 1.png";
// import img_2 from "../Images/servicePageImg/assets 2.png";
// import img_3 from "../Images/servicePageImg/assets 3.png";
// import img_4 from "../Images/servicePageImg/assets 4.png";
// import img_5 from "../Images/servicePageImg/assets 5.png";
// import img_6 from "../Images/servicePageImg/assets 6.png";
// import img_7 from "../Images/servicePageImg/assets 7.png";
// import img_8 from "../Images/servicePageImg/assets 8.png";
// import img_9 from "../Images/servicePageImg/assets 9.png";
// import img_10 from "../Images/servicePageImg/assets 10.png";
// import img_11 from "../Images/servicePageImg/assets 11.png";
// import img_12 from "../Images/servicePageImg/assets 12.png";
// import img_13 from "../Images/servicePageImg/assets 13.png";
// import img_14 from "../Images/servicePageImg/assets 14.png";
// import img_15 from "../Images/servicePageImg/assets 15.png";

// const services = [
//   {
//     title: "Game Development",
//     description: "Expertise in creating immersive and interactive games across    various platforms.",
//     details: "Detailed information about Game Development services...",
//     thumbnail: img_1,
//     popup: {
//       data1: "Casino game developement",
//         subPopUp: {
//           sub_1: "Card game developement",
//           sub_2: "Poker game developement",
//           sub_3: "Rummy game developement",
//           sub_4: "Rouelette game developement",
//           sub_5: "Slot game developement"
//         },
//       data2: "Interactive game developement",
//       data3: "Casual game developement",
//       data4: "Mobile game developement",

//     }
//   },
//   {
//     title: "Web Development",
//     description: "Building robust and scalable web applications tailored to your business needs.",
//     details: "Detailed information about Web Development services...",
//     thumbnail: img_2,
//     popup: {
//       data1: "Casino game developement",
//         subPopUp: {
//           sub_1: "Card game developement",
//           sub_2: "Poker game developement",
//           sub_3: "Rummy game developement",
//           sub_4: "Rouelette game developement",
//           sub_5: "Slot game developement"
//         },
//       data2: "Interactive game developement",
//       data3: "Casual game developement",
//       data4: "Mobile game developement",

//     }
//   },
//   {
//     title: "App Development",
//     description: "Developing user-friendly and feature-rich mobile applications for Android and iOS.",
//     details: "Detailed information about App Development services...",
//     thumbnail: img_4,
//     popup: {
//       data1: "Casino game developement",
//         subPopUp: {
//           sub_1: "Card game developement",
//           sub_2: "Poker game developement",
//           sub_3: "Rummy game developement",
//           sub_4: "Rouelette game developement",
//           sub_5: "Slot game developement"
//         },
//       data2: "Interactive game developement",
//       data3: "Casual game developement",
//       data4: "Mobile game developement",

//     }
//   },
//   {
//     title: "Social Media Marketing",
//     description: "Strategies to enhance your brand's presence and engagement across social media ",
//     details: "Detailed information about Social Media Marketing services...",
//     thumbnail: img_7,
//     popup: {
//       data1: "Casino game developement",
//         subPopUp: {
//           sub_1: "Card game developement",
//           sub_2: "Poker game developement",
//           sub_3: "Rummy game developement",
//           sub_4: "Rouelette game developement",
//           sub_5: "Slot game developement"
//         },
//       data2: "Interactive game developement",
//       data3: "Casual game developement",
//       data4: "Mobile game developement",

//     }
//   },
// ];

// function ServicePage() {
//   const [selectedService, setSelectedService] = useState(services[0]);

//   return (
//     <>
//       <main className="bg-black pt-[6rem] md:pt-0 min-h-screen">
//         <section>
//           <div className="relative">
//             <img
//               className="w-[320px] h-[300px] left-[0%] ml-[-10.2rem]"
//               src={circle}
//               alt="Decorative Circle"
//             />
//           </div>
//           <div className="w-full flex justify-center mt-[-4rem]">
//             <div className="w-[88%] flex flex-wrap justify-center sm:justify-between mt-[-12rem] sm:mt-[2rem]">
//               <div className="flex flex-col w-full lg:w-1/2">
//                 <h2 className="text-white p-5 text-4xl md:text-5xl font-bold">Services</h2>
//                 <p className="text-white text-lg m-3 mt-2">
//                   Discover the diverse range of services we offer to help you achieve your goals.We help you achieve your goals with seamless integration and dedicated support for ongoing success.
//                 </p>
//               </div>
//             </div>
//           </div>

//           <div className=" bg-black text-white">
//             <div className="flex flex-col lg:flex-row pl-8 lg:pl-20 pb-36">
//               <div className="lg:w-1/2 p-8">
//                 <div className="grid gap-4">
//                   {services.map((service, index) => (
//                     <div
//                       key={index}
//                       className={`bg-gray-800 p-4 rounded cursor-pointer ${selectedService.title === service.title ? 'crs-img' : ''}`}
//                       onClick={() => setSelectedService(service)}
//                     >
//                       <div className="flex justify-around">
//                      <div className="w-20 h-20 rounded-full overflow-hidden" >
//                         <img src={service.thumbnail} alt="#" />
//                       </div>
//                       <div>
//                       <h3 className="text-xl mb-2">{service.title}</h3>
//                       <p>{service.description}</p>
//                       </div>
//                     </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>

//               <div className="bg-gray-800 crs-img mr-20 p-4 rounded mb-4 lg:mb-0 lg:ml-8 mt-8 lg:mt-0 lg:w-1/2">
//                 <h3 className="text-2xl mb-4">{selectedService.title}</h3>
//                 {/* <p>{selectedService.details}</p> */}
//                 <div className="p-4">
//                   <div className="flex">
//                     <div className="grid gap-4">
//                     <div className="text-xl mb-2">
//                       {selectedService.popup.data1}
                      
//                     </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </main>
//     </>
//   );
// }

// export default ServicePage;

////////////////////////////////////////////////////////dont miss below

import React, { useState } from "react";
import circle from "../Images/circle.png";
import "./CoursePage.css";
import img_1 from "../Images/servicePageImg/assets 1.png";
import img_2 from "../Images/servicePageImg/assets 2.png";
import img_3 from "../Images/servicePageImg/assets 3.png";
import img_4 from "../Images/servicePageImg/assets 4.png";
import img_5 from "../Images/servicePageImg/assets 5.png";
import img_6 from "../Images/servicePageImg/assets 6.png";
import img_7 from "../Images/servicePageImg/assets 7.png";
import img_8 from "../Images/servicePageImg/assets 8.png";
import img_9 from "../Images/servicePageImg/assets 9.png";
import img_10 from "../Images/servicePageImg/assets 10.png";
import img_11 from "../Images/servicePageImg/assets 11.png";
import img_12 from "../Images/servicePageImg/assets 12.png";
import img_13 from "../Images/servicePageImg/assets 13.png";
import img_14 from "../Images/servicePageImg/assets 14.png";
import img_15 from "../Images/servicePageImg/assets 15.png";

const services = [
  {
    title: "Game Development",
    description: "Expertise in creating immersive and interactive games across various platforms.",
    thumbnail: img_1,
    popup: {
      data1: "Casino game development",
      subPopUp: {
        sub_1: "Card game development",
        sub_1_img: img_6,
        sub_2: "Poker game development",
        sub_2_img: img_7,
        sub_3: "Rummy game development",
        sub_3_img: img_8,
        sub_4: "Roulette game development",
        sub_4_img: img_9,
        sub_5: "Slot game development",
        sub_5_img: img_10,
      },
      data2: "Interactive game development",
      data3: "Casual game development",
      data4: "Mobile game development",
    },
  },
  {
    title: "Web Development",
    description: "Building robust and scalable web applications tailored to your business needs.",
    thumbnail: img_2,
    popup: {
      data1: "Web application development",
      subPopUp: {
        sub_1: "Front-end development",
        sub_2: "Back-end development",
        sub_3: "Full-stack development",
        sub_4: "Responsive web design",
        sub_5: "Custom web solutions",
      },
      data2: "E-commerce solutions",
      data3: "CMS development",
      data4: "API integration",
    },
  },
  {
    title: "App Development",
    description: "Developing user-friendly and feature-rich mobile applications for Android and iOS.",
    thumbnail: img_4,
    popup: {
      data1: "Native mobile app development",
      subPopUp: {
        sub_1: "Android app development",
        sub_2: "iOS app development",
        sub_3: "Cross-platform app development",
        sub_4: "Mobile UI/UX design",
        sub_5: "App testing and deployment",
      },
      data2: "Enterprise app solutions",
      data3: "App maintenance and support",
      data4: "App monetization strategies",
    },
  },
  {
    title: "Social Media Marketing",
    description: "Enhance your brand's presence and engagement across social media platforms.",
    thumbnail: img_7,
    popup: {
      data1: "Social media strategy development",
      subPopUp: {
        sub_1: "Content creation and management",
        sub_2: "Community engagement",
        sub_3: "Influencer marketing",
        sub_4: "Analytics and reporting",
        sub_5: "Paid advertising campaigns",
      },
      data2: "Brand reputation management",
      data3: "Social media audits",
      data4: "Campaign optimization",
    },
  },
];

function ServicePage() {
  const [selectedService, setSelectedService] = useState(services[0]);

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };
  

  return (
    <>
      <main className="bg-black pt-[6rem] md:pt-0 min-h-screen">
        <section>
          <div className="relative">
            <img
              className="w-[320px] h-[300px] left-[0%] ml-[-10.2rem]"
              src={circle}
              alt="Decorative Circle"
            />
          </div>
          <div className="w-full flex justify-center mt-[-4rem]">
            <div className="w-[88%] flex flex-wrap justify-center sm:justify-between mt-[-12rem] sm:mt-[2rem]">
              <div className="flex flex-col w-full lg:w-1/2">
                <h2 className="text-white p-5 text-4xl md:text-5xl font-bold">Services</h2>
                <p className="text-white text-lg m-3 mt-2">
                  Discover the diverse range of services we offer to help you achieve your goals.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-black text-white">
            <div className="flex flex-col lg:flex-row pl-8 lg:pl-20 pb-36">
              <div className="lg:w-1/2 p-8">
                <div className="grid gap-4">
                  {services.map((service, index) => (
                    <div
                      key={index}
                      className={`bg-gray-800 p-4 rounded cursor-pointer ${selectedService.title === service.title ? 'crs-img' : ''}`}
                      onClick={() => handleServiceClick(service)}
                    >
                      <div className="flex justify-around items-center">
                        <div className="w-20 rounded-full overflow-hidden">
                          <img src={service.thumbnail} alt={service.title} className="w-full h-auto" />
                        </div>
                        <div className="pl-5">
                          <h3 className="text-xl mb-2">{service.title}</h3>
                          <p>{service.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-gray-800 crs-img mr-20 p-4 rounded mb-4 lg:mb-0 lg:ml-8 mt-8 lg:mt-0 lg:w-1/2">
              <div className="flex justify-evenly mt-20">
              <div>
                <h3 className="text-2xl mb-4">{selectedService.title}</h3>
                <div className="p-4">
                  <div className="text-lg mb-2">{selectedService.popup.data1}</div>
                  <ul className="list-disc pl-6">
                    <li>{selectedService.popup.subPopUp.sub_1}</li>
                    <li>{selectedService.popup.subPopUp.sub_2}</li>
                    <li>{selectedService.popup.subPopUp.sub_3}</li>
                    <li>{selectedService.popup.subPopUp.sub_4}</li>
                    <li>{selectedService.popup.subPopUp.sub_5}</li>
                  </ul>
                  <div className="text-lg mt-4">{selectedService.popup.data2}</div>
                  <div className="text-lg mt-4">{selectedService.popup.data3}</div>
                  <div className="text-lg mt-4">{selectedService.popup.data4}</div>
                </div>
                </div>
                <div className="w-1/4 p-2 ">
                <img  src={selectedService.thumbnail} alt={selectedService.title} className="w-full h-auto" />
                </div>
                </div>
                <div>
                {/* {services.map((service, index) => (
                  <div className="flex justify-around items-center">
                  <div className="w-20 h-20 rounded-full overflow-hidden">
                    <img src={service.thumbnail} alt={service.title} className="w-full h-auto" />
                  </div>
                  <div>
                    <h3 className="text-xl mb-2">{service.title}</h3>
                    <p>{service.description}</p>
                  </div>
                </div>

                ))} */}

                </div>


{/* 
<a href="#" class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

<h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{selectedService.title}</h5>
<p class="font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
</a> */}





              </div>
            </div>
          </div>
          
        </section>
      </main>

    </>
  );
}

export default ServicePage;
