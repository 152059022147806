import React from "react";
import styles from "./Games.module.css";
import { Link } from "react-router-dom";
import imgone from "../Images/1111111.png";
import imgtwo from "../Images/2222222.png";
import imgthree from "../Images/333333.png";
import imgfour from "../Images/44444.png";

const Games = () => {
  return (
    <section>
      <div className={styles.main__div}>
        <div className={styles.game__div}>
          <div>
            <img src={imgone} alt="imageone" className="game__img" />
          </div>
          <div className={styles.desc}>
            <h3>Casino Games</h3>
            <p>
              Casino games" refer to various games of chance and skill that are
              typically played in a casino setting. These games are designed for
              gambling, where players can wager money on different outcomes with
              the hope of winning more money or prizes.
            </p>
            <Link to="/entertainment">
              <button className="vdo-icon hover:bg-[#21C8ED] bg-transparent sm:w-[140px] w-[100px] h-[30px] sm:h-[50px] text-[8px] sm:text-[13px] font-[700] border-[0.568px] border-white mt-[2rem] hover:bg- transition-colors duration-500 text-white  py-2 px-4">
                VIEW MORE
              </button>
            </Link>
          </div>
        </div>
        <div className={styles.game__div}>
          <div className={styles.desc}>
            <h3>Retro Games</h3>
            <p>
              Retro games are video games that were popular in the past, Retro
              games often evoke feelings of nostalgia for those who played them
              during their original release or during their childhood.
            </p>
            <Link to="/entertainment#retro">
              <button className="vdo-icon hover:bg-[#21C8ED] bg-transparent sm:w-[140px] w-[100px] h-[30px] sm:h-[50px] text-[8px] sm:text-[13px] font-[700] border-[0.568px] border-white mt-[2rem] hover:bg- transition-colors duration-500 text-white  py-2 px-4">
                VIEW MORE
              </button>
            </Link>
          </div>
          <div>
            <img src={imgtwo} alt="imageone" className="game__img" />
          </div>
        </div>
        <div className={styles.game__div}>
          <div>
            <img src={imgthree} alt="imageone" className="game__img" />
          </div>
          <div className={styles.desc}>
            <h3>Casual Games</h3>
            <p>
              Casual games are a category of video games that are designed to be
              easy to learn, They are often simple to understand, featuring
              straightforward mechanics and intuitive controls. Casual games are
              making them suitable for quick entertainment .
            </p>
            <Link to="/entertainment#casual">
              <button className="vdo-icon hover:bg-[#21C8ED] bg-transparent sm:w-[140px] w-[100px] h-[30px] sm:h-[50px] text-[8px] sm:text-[13px] font-[700] border-[0.568px] border-white mt-[2rem] hover:bg- transition-colors duration-500 text-white  py-2 px-4">
                VIEW MORE
              </button>
            </Link>
          </div>
        </div>
        <div className={styles.game__div}>
          <div className={styles.desc}>
            <h3>Strategy Games</h3>
            <p>
              Strategy games entail tactical thinking, planning, and resource
              management to achieve objectives while outmaneuvering opponents
              through strategic maneuvers in a virtual battlefield.
            </p>
            <Link to="/entertainment#strategy">
              <button className="vdo-icon hover:bg-[#21C8ED] bg-transparent sm:w-[140px] w-[100px] h-[30px] sm:h-[50px] text-[8px] sm:text-[13px] font-[700] border-[0.568px] border-white mt-[2rem] hover:bg- transition-colors duration-500 text-white  py-2 px-4">
                VIEW MORE
              </button>
            </Link>
          </div>{" "}
          <div>
            <img src={imgfour} alt="imageone" className="game__img" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Games;

// <Link to="/entertainment#casual">
// {" "}
// <button className="vdo-icon hover:bg-[#21C8ED] bg-transparent sm:w-[140px] w-[100px] h-[30px] sm:h-[50px] text-[8px] sm:text-[13px] font-[700] border-[0.568px] border-white mt-[2rem] hover:bg- transition-colors duration-500 text-white  py-2 px-4">
//   VIEW MORE
// </button>
// </Link>
