import React, { useEffect, useState } from "react";
import circle from "../Images/circle.png";
import hirecircle from "../Images/hirecircle.png";
import { FaPhoneAlt } from "react-icons/fa";
import hirebg from "../Images/hirebg.png";
import whatsapp from "../Images/whatsapp.png";
import howtohire from "../Images/howtohire.png";
import ModalForm from "./ModalForm";
import image from "../temparory/abinas.png";
import "./HireFromPage.css";
import axios from "axios";
import NewSection from "./NewSection";
import Expertise from "./Expertise";

const HireFromPage = () => {
  const [showMdal, setshowModal] = useState(false);
  const modalclosed = () => {
    setshowModal(false);
  };
  const [hires, sethires] = useState([]);
  useEffect(() => {
    let data;
    axios
      .get("https://www.emergiogames.com/emergio/hire", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        data = res.data;
        sethires(data);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <main className="bg-black md:pt-0 pt-[4rem] sm:p-0">
        <section>
          <div className="relative">
            <img
              className="w-[320px] h-[300px] left-[0%] ml-[-15rem]"
              src={circle}
              alt=""
            />
          </div>
          <div className="w-[100%] mt-[-4rem] flex justify-center">
            <div className="flex flex-wrap justify-center sm:justify-between  w-[88%] mt-[-12rem] sm:mt-[2rem] ">
              <div className="  flex flex-col flex-wrap w-[90%] lg:w-[40%]">
                <h2 className="text-white text-[39px] md:text-[54px] font-[700]">
                  Building Bridges to Success: Hire from Us!
                </h2>
                <p className="text-white text-[13px] sm:text-[16px] mt-3">
                  we take pride in nurturing talent and producing top-tier
                  professionals ready to excel in their respective fields. By
                  hiring from us, you gain access to a pool of exceptional
                  candidates who possess both the knowledge and practical skills
                  necessary to thrive in today's competitive workforce.
                </p>
                <button
                  onClick={() => setshowModal(true)}
                  className="vdo-icon z-30 hover:bg-[#21C8ED] bg-transparent w-[140px] h-[50px]  text-[13px] font-[700] border-[0.568px] border-white mt-[2rem] hover:bg- transition-colors duration-500 text-white  py-2 px-4 "
                >
                  CONTACT US
                </button>
              </div>
              <div>
                <img
                  className="hide md:mr-[5em] ml-[-0.5em] z-10 mt-[-4rem] md:mt-[-4.5rem]   "
                  src={hirecircle}
                  alt=""
                />
              </div>
            </div>
          </div>
          {showMdal && <ModalForm close={modalclosed} />}

          {/* new section */}

          <NewSection />

          {/* 
          end new section */}

          <div className=" flex flex-wrap justify-evenly items-center align-middle ml-4  ">
            <div className="mydiv sm:w-[370px] w-[90%] h-100 sm:h-[300px] text-white rounded-[6px] p-[2em] bg-[#191616] mb-12 ">
              <h2 className="text-[22px] font-[700] pt-1">Quality Education</h2>
              <p className=" text-[#D9D9D9] text-[16px] font-[400] pt-5">
                Our institute is renowned for its commitment to academic
                excellence and practical learning. We equip our students with
                the latest industry-relevant knowledge and skills, ensuring they
                are prepared to tackle real-world challenges from day one.
              </p>
            </div>
            <div className="mydiv sm:w-[370px] w-[90%] h-[auto] sm:h-100 text-white rounded-[6px] p-[2em] bg-[#191616] mb-12">
              <h2 className="text-[22px] font-[700]">Diverse Talent Pool</h2>
              <p className="text-[#D9D9D9] text-[16px] font-[400] pt-3">
                With a diverse student body representing various backgrounds,
                cultures, and perspectives, we offer a rich tapestry of talent
                for employers to choose from. Whether you seek fresh
                perspectives or specific expertise, we have the right candidate
                for your organization.
              </p>
            </div>
            <div className="sm:w-[370px] w-[90%] h-[auto]">
              <h1 className="text-[25px] text-[#21C8ED]">
                Make your Ideas <br />
                Come to Life with us
              </h1>
              <p className="text-[#D9D9D9] text-[16px] font-[400] pt-3">
                Join the ranks of leading companies who have benefited from
                hiring our exceptional graduates. Partner with Emergio to access
                top talent, drive innovation, and build a brighter future
              </p>
              <button
                onClick={() => setshowModal(true)}
                className="vdo-icon hover:bg-[#21C8ED] bg-transparent w-[140px] h-[50px]  text-[13px] font-[700] border-[0.568px] border-white mt-[2rem] hover:bg- transition-colors duration-500 text-white  py-2 px-4"
              >
                CONTACT US
              </button>
            </div>
          </div>

          <Expertise />

          <div className="bg-[#100E0E] flex justify-center mt-[6rem] w-[100%]">
            <div className="w-[90%] flex flex-wrap justify-around gap-8 items-center">
              <div>
                <img
                  className="md:w-[476px] md:mt-0 mt-[3rem]"
                  src={howtohire}
                  alt=""
                />
              </div>
              <div div className="w-[476px] flex flex-col pb-[3rem] gap-5">
                <h1 className="text-[41px] text-[#21C8ED] mt-[3rem]">
                  How to Hire from Us?
                </h1>
                <div>
                  <h2 className="text-white text-[27px] font-[500]">
                    Browse our Talent Pool
                  </h2>
                  <p className="text-[#D9D9D9] text-[16px]">
                    Explore profiles of our graduates and current students to
                    find candidates who match your hiring needs. Our online
                    platform provides easy access to resumes, portfolios, and
                    contact information.
                  </p>
                </div>
                <div>
                  <h2 className="text-white text-[27px] font-[500]">
                    Attend Career Fairs
                  </h2>
                  <p className="text-[#D9D9D9] text-[16px]">
                    Join us at our regular career fairs and networking events to
                    connect with potential candidates face-to-face. These events
                    offer a great opportunity to showcase your organization and
                    engage with our talented students directly.
                  </p>
                </div>
                <div>
                  <h2 className="text-white text-[27px] font-[500]">
                    Post Job Openings
                  </h2>
                  <p className="text-[#D9D9D9] text-[16px]">
                    Utilize our job posting platform to advertise vacancies
                    within your organization. By reaching out to our student and
                    alumni community, you can attract qualified candidates who
                    are actively seeking employment opportunities.
                  </p>
                </div>
                <div>
                  <h2 className="text-white text-[27px] font-[500]">
                    Collaborate on Projects
                  </h2>
                  <p className="text-[#D9D9D9] text-[16px]">
                    Consider collaborating with our institute on research
                    projects, industry-sponsored initiatives, or guest lectures.
                    These collaborations not only benefit our students but also
                    provide a platform for you to identify and engage with
                    potential hires.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-[100%] flex justify-center mt-[8rem] lg:pb-[3rem] md:pb-[3rem] sm:pb-[8rem] pb-[9rem]">
            <div className="hireinput w-[90%]lg:mt-0 sm:mt-[-4rem]  h-[304px] flex flex-wrap justify-center items-center gap-6 lg:mb-0 md:mb-[4rem]">
              <div className="">
                <img
                  className=" hirebg relative hidden sm:block lg:w-[100%] md:w-[100vw] lg:h-auto sm:h-[390px]   "
                  src={hirebg}
                  alt=""
                />
              </div>
              <div className="absolute top  gap-10  flex  flex-wrap">
                <div className=" sm:px-0 px-3 flex justify-center items-start flex-col lg:mt-0 md:mt-[3rem] sm:mt-[4rem]">
                  <h2 className="text-white text-[35px] font-[600]">
                    Let’s Talk!
                  </h2>
                  <p className="text-[#21C8ED] text-[20px] font-[400]">
                    Ask us anything or just say Hi...
                  </p>
                </div>
                <div className="hirebg2 lg:w-[537px] lg:h-[315px] w-[100%]  h-[200px]  flex justify-center  items-center">
                  <div className="numberplate flex flex-col justify-center items-center">
                    <span className="flex gap-3">
                      {" "}
                      <img src={whatsapp} alt="" />{" "}
                      <a
                        className="text-[20px] font-[600] ml-[-0.3em]"
                        href="https://api.whatsapp.com/send?phone=917594088814"
                      >
                        {" "}
                        Chat With Us
                      </a>
                    </span>
                    <a
                      className="flex text-[20px] font-[600] gap-3 mt-[0.3em]"
                      href="tel:+917594088814"
                    >
                      <FaPhoneAlt />{" "}
                      <span className="mt-[-0.3em]">+91 7594088814</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default HireFromPage;
