import React from 'react'
import axios from "axios";
class Form extends React.Component {
  state = {
    name: '',
    email: '',
    phone: '',
    place: '',
    isSubmitted: false,
    formData: new FormData(),
    status: '',
    message: '',
  }
  constructor(props) {
    super(props);
    this.roleInputRef = React.createRef();
  }
  changeName = event => {
    this.setState({ name: event.target.value });
  }
  changePhone = event => {
    this.setState({ phone: event.target.value });
  }
  changeEmail = event => {
    this.setState({ email: event.target.value });
  }
  changePlace = event => {
    this.setState({ place: event.target.value });
  }
  handleSubmit = event => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', this.state.name);
    formData.append('phone', this.state.phone);
    formData.append('place', this.state.place);
    formData.append('email', this.state.email);
    console.log(this.state.name,
      this.state.phone,
      this.state.place,
      this.state.email)
    this.setState({
      name: '', email: '',
      phone: '',message:''
    });
    axios.post(`http://localhost:8000/emergio/units/form`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        this.setState({ status: res.data.status, message: res.data.message })
      })
    this.setState({ isSubmitted: true });
    setTimeout(() => {
      this.props.onClose();
    }, 2000);
  }
  render() {
    const { isSubmitted, status, message } = this.state;
    return (
      <>
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50 flex sm:pt-[7rem]">
          <div className="relative p-8 bg-white w-[90%] md:w-[60%] m-auto rounded-lg">
            <h2 className=" px-8  text-[#21C8ED] text-[25px] md:text-[34px] font-[700]">
              Welcome to Emergio Games!
            </h2>
            <p className="px-8 pb-3 text-[14px] font-[400]">
              Please fill this form and explore our courses ...
            </p>
            <form onSubmit={this.handleSubmit}>
              <div className="flex justify-center gap-5 mt-[2em] flex-wrap ">
                <div className="">
                  <input
                    placeholder="Name"
                    type="text"
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={this.changeName}
                    className="w-[100%] md:w-[350px] px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
                <div className="">
                  <input placeholder="Phone No"
                    type="tel"
                    id="phone"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.changePhone}
                    className="w-[100%] md:w-[350px] px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
              </div>
              <div className="flex justify-center gap-5 mt-[1em] flex-wrap ">
                <div className="">
                  <input
                    placeholder="Email"
                    type="email"
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.changeEmail}
                    className="w-[100%] md:w-[350px] px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
                <div className="">
                  <input placeholder="Place"
                    type="text"
                    id="place"
                    name="place"
                    value={this.state.place}
                    onChange={this.changePlace}
                    className="w-[100%] md:w-[350px] px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
              </div>
              <button
                  type="submit"
                  className="w-[150px] h-[36px] bg-[#21C8ED]  text-white  text-[16px] rounded-[9px] mt-[2em] flex justify-center items-center text-center font-[700] mt-[1rem]   hover:bg-blue-600 transition-colors float-end"
                >
                  Submit
              </button>
            </form>
          </div>
        </div>

      </>
    )
  }
}
export default Form
