import React from 'react'
import ServicePage from '../ServicePage/ServicePage'

function Services() {
  return (
    <div>
        <ServicePage/>
    </div>
  )
}

export default Services