import React, { useState } from "react";
import { FaRegSquarePlus } from "react-icons/fa6";
import { FiMinusSquare } from "react-icons/fi";
import { Link } from "react-router-dom";
import { to } from "react-spring";

const Expertise = () => {
  const Data = [
    {
      id: 1,
      question: "Game Development",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est dolore illo dolores quia nemo doloribus quaerat, magni numquam repellat reprehenderit.",
    },
    {
      id: 10,
      question: "Game Art",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est dolore illo dolores quia nemo doloribus quaerat, magni numquam repellat reprehenderit.",
    },
    {
      id: 2,
      question: "Flutter",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est dolore illo dolores quia nemo doloribus quaerat, magni numquam repellat reprehenderit.",
    },

    {
      id: 6,
      question: "Human Resource Management",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est dolore illo dolores quia nemo doloribus quaerat, magni numquam repellat reprehenderit.",
    },
    {
      id: 7,
      question: "Digital Marketing",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est dolore illo dolores quia nemo doloribus quaerat, magni numquam repellat reprehenderit.",
    },

    {
      id: 9,
      question: "Python",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est dolore illo dolores quia nemo doloribus quaerat, magni numquam repellat reprehenderit.",
    },
    // Add more question data objects as needed
  ];

  return (
    <section className="questions">
      <div className="title">
        <h2>Realms of our Expertise</h2>
      </div>
      <div className="section-center">
        <Questions data={Data} />
      </div>
    </section>
  );
};

const Questions = ({ data }) => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const toggleQuestion = (id) => {
    setActiveQuestion(activeQuestion === id ? null : id);
  };

  return (
    <>
      {data.map(({ id, question, answer }) => (
        <Link to={`/hiree/${id}`} className={`question  show-text`} key={id}>
          <div className="question-title">
            <p>{question}</p>
          </div>
        </Link>
      ))}
    </>
  );
};

export default Expertise;
