import React from 'react'
import VideoSec from '../VideoSec/VideoSec'

import HomePage from '../HomePage/HomePage'




const Home = () => {
  return (
    <>
    <VideoSec/>
    <HomePage/>
  

 
    
      
    </>
  )
}

export default Home
